/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { LogHandler, LogLevel } from './log-handler';
import { getCallOutcomes, getParentBusinessOutcomes, lcmRegister, register, stateSync } from './messaging';
import { CallBusinessFields, CurrentCallInformation } from './types';
import { BASE_API_URL } from './utils';

export enum AcqueonEventSubTopics {
    STATE_SYNC = 'StateSync',
    CALL_INFO = 'CallInfo',
    OUTBOUND_CALL_STATE_CHANGE = 'OutBoundCallStateChange',
    GET_CONTACT_DETAIL = 'GetContactDetail',
    CONTACT_DETAIL = 'ContactDetail',
    CALL_OUTCOME = 'CallOutCome',
    PARENT_BUSINESS_OUTCOME = 'BOGroupParent',
    CHILD_BUSINESS_OUTCOME = 'BusinessOutcomeParent',
    GET_MODE_INFORMATION_RESULT = 'GetModeInformationResult',
    WEB_SERVICE_INFO = 'WebserviceInfo'
}

export class EventHandler {
    private setCurrentCallState: Dispatch<SetStateAction<CurrentCallInformation>>;
    private setAgentNextStatus: Dispatch<SetStateAction<string>>;
    private setCallInProgress: Dispatch<SetStateAction<boolean>>;
    private readonly plainOrigin;
    private saToken = 'dev';
    private username: string | undefined;


    constructor(
        setCurrentCallState: Dispatch<SetStateAction<CurrentCallInformation>>, 
        setAgentNextStatus: Dispatch<SetStateAction<string>>,
        setCallInProgress: Dispatch<SetStateAction<boolean>>
    ) {
        this.setCurrentCallState = setCurrentCallState;
        this.setAgentNextStatus = setAgentNextStatus;
        this.setCallInProgress = setCallInProgress;
        const originSplit = window.location.href.split('/');
        this.plainOrigin = `${originSplit[0]}//${originSplit[2]}`;
    }

    setSaToken(token: string) {
        this.saToken = token;
    }

    receiveMessage(event: MessageEvent) {
        if (event.origin === this.plainOrigin) return;
        console.log(
            '*** PLUGIN received message: ',
            JSON.stringify(event.data)
        );

        switch(event.data?.event) {
            case 'get_user':
                const username = event.data.message?.username;
                if (!username) {
                    this.addErrorMessageLog('Unable to get agent username from smartagent');
                    break;
                }
                LogHandler.setUsername(username);
                this.username = username;
                break;
            case 'get_token':
                if (!event.data.message) {
                    this.addErrorMessageLog('Unable to get token from smartagent');
                    break;
                }
                this.setSaToken(event.data.message);
                LogHandler.setSaToken(event.data.message);
                break;
            case 'next_status':
                const agentStatus = event.data.message;
                if (!agentStatus) {
                    this.addErrorMessageLog('Unable to get next status from smartagent');
                    break;
                }
                this.setAgentNextStatus(agentStatus);
                break;
            case 'iframe_message': 
                this.handleAcqueonEvent(event.data.message);
                break;
            default:
                console.debug(`Ignoring ${event.data.event} event from smartagent`);
                break;
        }
    }

    private handleAcqueonEvent(eventData: any) {
        if (eventData === 'doDesktopRegistration') {
            setTimeout(() => {
                register();
                lcmRegister();
                stateSync();
            }, 500);
        }

        this.handleEvent(eventData);
        this.handleErrorEvent(eventData);
    }

    private addLog(level: LogLevel, event: any) {
        LogHandler.addLog({
            logLevel: level,
            message: JSON.stringify(event)
        });
    }

    private addErrorMessageLog(errorMessage: string) {
        console.error(errorMessage);
        LogHandler.addLog({
            logLevel: LogLevel.ERROR,
            message: errorMessage
        });
    }

    private getOutcomes(
        currentCall: CurrentCallInformation,
        callId: string, 
        accountNumber: string
    ) {
        if (!currentCall?.callOutcomes || currentCall.callOutcomes.length < 1) {
            getCallOutcomes(callId, accountNumber);
        }
        if (
            !currentCall?.parentBusinessOutcomes ||
            currentCall.parentBusinessOutcomes.length < 1
        ) {
            getParentBusinessOutcomes(callId, accountNumber);
        }
    }

    private updateAccountAndCallId(
        currentCall: CurrentCallInformation,
        callId: string, 
        accountNumber: string
    ): CurrentCallInformation {
        this.getOutcomes(currentCall, callId, accountNumber);
        return {
            ...currentCall,
            callId,
            accountNumber,
            agentUserId: currentCall.agentUserId || this.username
        };
    }

    private handleEvent(event: any) {
        if (!event.message || !event.subtopic || event.topic === 'Error') return;
        const message = event.message;
        switch (event.subtopic) {
            case AcqueonEventSubTopics.WEB_SERVICE_INFO:
                this.setCurrentCallState((currentCall) => {
                    const accountNumber = message.accountNumber;
                    const callId = message.callId;
                    if(accountNumber && callId) {
                        return this.updateAccountAndCallId(currentCall, callId, accountNumber);
                    }
                    return currentCall;
                });
                this.addLog(LogLevel.DEBUG, event);
                break;
            case AcqueonEventSubTopics.STATE_SYNC:
                if (message.wsData?.length > 0) {
                    this.setCurrentCallState((currentCall) => {
                        const accountNumber = message.wsData[0].accountNumber;
                        const callId = message.wsData[0].callid;
                        return this.updateAccountAndCallId(currentCall, callId, accountNumber);
                    });
                } else if (message.initState) {
                    this.setCurrentCallState((currentCall) => ({
                        ...currentCall,
                        acqueonInitialised: message.initState === 'Loaded'
                    }));
                }
                this.addLog(LogLevel.DEBUG, event);
                break;
            case AcqueonEventSubTopics.GET_CONTACT_DETAIL:
                this.setCurrentCallState((currentCall) => {
                    return this.updateAccountAndCallId(
                        currentCall, 
                        message.callid, 
                        message.AccountNumber
                    );
                });
                
                this.addLog(LogLevel.DEBUG, event);
                break;
            case AcqueonEventSubTopics.CONTACT_DETAIL:
                const businessFields: CallBusinessFields = {};
                message.response.forEach((field: { FieldName: string; Value: string }) => {
                    businessFields[field.FieldName] = field.Value;
                });
                this.setCurrentCallState((currentCall) => ({
                    ...currentCall,
                    businessFields
                }));
                this.addLog(LogLevel.DEBUG, event);
                break;
            case AcqueonEventSubTopics.CALL_INFO:
                this.setCurrentCallState((currentCall) => {
                    const endTime = currentCall.endTime
                        ? currentCall.endTime
                        : message.callStatus === 'ACW'
                        ? Date.now()
                        : undefined;
                    return {
                        ...currentCall,
                        callId: message.callid,
                        callStatus: message.callStatus,
                        endTime
                    };
                });
                this.addLog(LogLevel.DEBUG, event);
                break;
            case AcqueonEventSubTopics.OUTBOUND_CALL_STATE_CHANGE:
                if (message.call?.event === 'DROPPED') {
                    this.handleCallDroppedEvent();
                } else {
                    this.handleOutboundCallStateChange(message);
                }
                this.addLog(LogLevel.DEBUG, event);
                break;
            case AcqueonEventSubTopics.CALL_OUTCOME:
                this.setCurrentCallState((currentCall) => ({
                    ...currentCall,
                    callOutcomes: message.response.map(
                        (outcome: { OutComeID: string; Description: string }) => ({
                            id: outcome.OutComeID,
                            label: outcome.Description
                        })
                    )
                }));
                this.addLog(LogLevel.DEBUG, event);
                break;
            case AcqueonEventSubTopics.PARENT_BUSINESS_OUTCOME:
                this.setCurrentCallState((currentCall) => ({
                    ...currentCall,
                    parentBusinessOutcomes: message.response.map(
                        (outcome: { ParentId: string; ParentName: string }) => ({
                            id: outcome.ParentId,
                            label: outcome.ParentName
                        })
                    )
                }));
                this.addLog(LogLevel.DEBUG, event);
                break;
            case AcqueonEventSubTopics.CHILD_BUSINESS_OUTCOME:
                this.setCurrentCallState((currentCall) => ({
                    ...currentCall,
                    childBusinessOutcomes: message.response.map(
                        (outcome: {
                            OutComeID: string;
                            Description: string;
                            LeadScore: string;
                        }) => ({
                            id: outcome.OutComeID,
                            label: outcome.Description,
                            leadScore: outcome.LeadScore
                        })
                    )
                }));
                this.addLog(LogLevel.DEBUG, event);
                break;
            case AcqueonEventSubTopics.GET_MODE_INFORMATION_RESULT:
                this.setCurrentCallState((currentCall) => ({
                    ...currentCall,
                    campaignInfo: message.response
                }));
                this.addLog(LogLevel.DEBUG, event);
                break;
            default:
                this.addLog(LogLevel.INFO, event);
                break;
        }
    }

    private handleCallDroppedEvent() {
        this.setCallInProgress(false);
        this.setCurrentCallState((currentCall) => {
            if (!currentCall.contactNumber) return {};
            const contact = {
                callStatus: currentCall.callOutcome,
                campaignName: currentCall.campaignInfo?.CampaigniD,
                startTime: currentCall.startTime,
                endTime: currentCall.endTime,
                accountNumber: currentCall.accountNumber,
                queueName: currentCall.queueName
            };
            axios
                .post(`${BASE_API_URL}/contact-history/${currentCall.contactNumber}`, contact, {
                    headers: { 'X-Amz-Security-Token': this.saToken }
                })
                .catch((err) => console.error('Failed to send contact history ::', err));

            return {};
        });
    }

    private handleOutboundCallStateChange(message: any) {
        this.setCallInProgress(true);
        const callStatus = message.call.ResponseActual.data.CallStatus;
        const billAccount  = message.call.CallVariables?.BillAccount;
        const callId = message.call.id;
        const accountNumber = message.call.ResponseActual.data.LCMKey;
        
        this.setCurrentCallState((currentCall: CurrentCallInformation) => {
            this.getOutcomes(currentCall, callId, accountNumber);
            return {
                ...currentCall,
                contactNumber: message.call.ResponseActual.data.ContactNumber,
                callStatus,
                pacingMode: message.call.ResponseActual.data.PacingMode,
                businessFields: message.call.BusinessFields,
                agentUserId: message.call.User.UserId,
                accountNumber: accountNumber,
                startTime:
                    !currentCall.startTime && callStatus === 'CustAnswered' ? Date.now() : undefined,
                callType: message.call.CallType,
                billAccount: billAccount,
                queueName: message.call.ResponseActual.data.CustCallQueue
            };
        });
    }

    private handleErrorEvent(event: any) {
        if (event.topic !== 'Error') return;
        this.addLog(LogLevel.ERROR, event);
    }
}
