import React, { useEffect, useRef, useState } from 'react';
import SmartAgent from '@missionlabs/smartagent-app-components/dist/smartagent';
import { Box } from '@missionlabs/smartagent-app-components';
import '../../node_modules/@missionlabs/smartagent-app-components/dist/index.css';
import CallOverview from './CallOverview';
import ViewSwitcher from './ViewSwitcher';
import CustomerDetails from './CustomerDetails';
import CampaignInfo from './CampaignInfo';
import Outcome from './Outcome';
import { ContactAttributes, CurrentCallInformation } from '../types';
import { EventHandler } from '../event-handler';
import { getCampaignInfo, sendNotReadyState } from '../messaging';
import { LogHandler } from '../log-handler';

interface Props {
    sm?: SmartAgent;
}

const views = ['Customer details', 'Campaign info', 'Outcome'];

const Main: React.FC<Props> = (props) => {
    const sm = useRef<SmartAgent>(props.sm || new SmartAgent());
    const [selectedView, setSelectedView] = useState<string>(views[0]);
    const [currentCallState, setCurrentCallState] = useState<CurrentCallInformation>({});
    const [agentNextStatus, setAgentNextStatus] = useState<string>('');
    const [callInProgress, setCallInProgress] = useState<boolean>(false);

    new LogHandler();
    const eventHandler = new EventHandler(setCurrentCallState, setAgentNextStatus, setCallInProgress);

    useEffect(() => {
        setup();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (agentNextStatus && agentNextStatus !== 'Available' && !callInProgress) {
            sendNotReadyState(agentNextStatus);
        }
    }, [agentNextStatus, callInProgress])

    const setup = async () => {
        refreshSaToken();
        postGetUserEvent();

        sm.current.on('iframe_message', (event: MessageEvent) => {
            console.log('PLUGIN: received iframe message event: ', event);
            eventHandler.receiveMessage(event);
        });

        window.addEventListener(
            'message',
            async (e) => {
                eventHandler.receiveMessage(e);
            },
            false
        );

        sendGetUserMessage();
    };

    const postGetUserEvent = () => {
        window.parent?.postMessage(
            {
                source: 'tw-acqueon-plugin',
                event: 'get_user',
                type: 'smartagent'
            },
            '*'
        );
    }

    const refreshSaToken = async () => {
        const token = await sm.current.getToken();
        eventHandler.setSaToken(token);
        LogHandler.setSaToken(token);
    }

    useEffect(() => {
        if (currentCallState.contactNumber) {
            window.parent?.postMessage(
                {
                    accountNumber: currentCallState.contactNumber,
                    source: 'tw-acqueon-plugin',
                    event: 'acqueon_contact',
                    type: 'smartagent'
                },
                '*'
            );
            refreshSaToken();
        }
    }, [currentCallState.contactNumber]);


    useEffect(() => {
        if (currentCallState.accountNumber) {
            sm.current.updateContact({
                [ContactAttributes.LCM_KEY]: currentCallState.accountNumber
            });
        }
    }, [currentCallState.accountNumber])

    useEffect(() => {
        if (currentCallState.billAccount) {
            sm.current.updateContact({
                [ContactAttributes.BILL_ACCOUNT]: currentCallState.billAccount
            });
        } else {
            sm.current.updateContact({
                [ContactAttributes.BILL_ACCOUNT]: ''
            });
        }
    }, [currentCallState.billAccount])

    useEffect(() => {
        if (currentCallState.callType) {
            sm.current.updateContact({
                [ContactAttributes.CALL_TYPE]: currentCallState.callType
            });
        }
    }, [currentCallState.callType])

    useEffect(() => {
        if (currentCallState.accountNumber && currentCallState.callId) {
            getCampaignInfo(currentCallState.callId, currentCallState.accountNumber);
        }
    }, [currentCallState.callId, currentCallState.accountNumber]);

    const sendGetUserMessage = () => {
        window.parent?.postMessage({
            source: 'tw-acqueon-plugin',
            event: 'get_user',
            type: 'smartagent'
        }, '*');
    }


    const renderView = () => {
        switch (selectedView) {
            case 'Customer details':
                return <CustomerDetails contactData={currentCallState.businessFields || {}} />;
            case 'Campaign info':
                return (
                    <CampaignInfo
                        campaignInfo={currentCallState.campaignInfo || {}}
                        queueName={currentCallState.queueName}
                    />
                );
            case 'Outcome':
                return (
                    <Outcome
                        currentCallState={currentCallState}
                        setCurrentCallState={setCurrentCallState}
                    />
                );
        }
    };

    const renderInner = () => {
        return (
            <div className="plugin" style={{ height: '100%' }}>
                <CallOverview currentCall={currentCallState} />
                <ViewSwitcher
                    views={views}
                    selectedView={selectedView}
                    setSelectedView={setSelectedView}
                    callStatus={currentCallState.callStatus}
                />
                <Box className="plugin__views" alt header={selectedView} collapse>
                    {renderView()}
                </Box>
            </div>
        );
    };

    const isEmbedded = window.parent !== window;
    return isEmbedded ? (
        renderInner()
    ) : (
        <Box alt header="Acqueon plugin" collapse>
            {renderInner()}
        </Box>
    );
};

export default Main;
