import { nanoid } from 'nanoid';
import { LogHandler, LogLevel } from './log-handler';
import { SMARTAGENT_URL } from './utils';

export interface SendMessageParams {
    channel: string;
    topic?: string;
    subTopic?: string;
    message?: MessageParams | string;
}

interface MessageParams {
    channel: string;
    topic: string;
    subtopic: string;
    source: string;
    data?: any;
}

export interface BusinessOutcomeRequest {
    callid: string;
    AccountNumber: string;
    OutcomeId?: number;
    LeadScore?: number;
    UserId: string;
    AgentComments?: string;
    TargetAmount?: number;
}

const getSubscriberID = () => {
    const key = 'subscriberID';
    let ID = localStorage.getItem(key);

    if (!ID) {
        ID = nanoid();
        localStorage.setItem(key, ID);
    }

    return ID;
};

export const sendMsg = (params: SendMessageParams) => {
    window.parent?.postMessage(
        {
            channel: params.channel,
            topic: params.topic,
            subTopic: params.subTopic,
            message: params.message,
            ResponseUrl: SMARTAGENT_URL,
            subscriberID: getSubscriberID(),
            type: 'smartagent',
            event: 'post_iframe'
        },
        '*'
    );

    LogHandler.addLog({
        logLevel: LogLevel.DEBUG,
        message: JSON.stringify(params)
    });
};

export const register = () => {
    sendMsg({
        channel: 'AcqueonDesktop',
        topic: '*',
        subTopic: '*',
        message: 'register',
    });
};

export const lcmRegister = () => {
    sendMsg({
        channel: 'LCM',
        topic: '*',
        subTopic: '*',
        message: 'register'
    });
};

export const stateSync = () => {
    sendMsg({
        channel: 'LCM',
        message: {
            subtopic: 'StateSync',
            channel: 'LCM',
            topic: 'WebService',
            source: 'SmartAgent'
        }
    });
};

export const answerCall = () => {
    sendMsg({
        channel: 'Amazon',
        message: {
            channel: 'Amazon',
            topic: 'OutboundCall',
            subtopic: 'MakeCall',
            source: 'SmartAgent',
        }
    });
};

export const endCall = () => {
    sendMsg({
        channel: 'Amazon',
        message: {
            channel: 'Amazon',
            topic: 'OutboundCall',
            subtopic: 'Disconnect',
            source: 'SmartAgent'
        }
    });
};

export const skipCall = () => {
    sendMsg({
        channel: 'Amazon',
        message: {
            channel: 'Amazon',
            topic: 'OutboundCall',
            subtopic: 'Skip',
            source: 'SmartAgent'
        }
    });
};

export const clearContact = () => {
    sendMsg({
        channel: 'Amazon',
        message: {
            channel: 'Amazon',
            topic: 'OutboundCall',
            subtopic: 'ClearContact',
            source: 'SmartAgent'
        }
    });
};

export const 
getCallOutcomes = (callId: string, accountNumber: string) => {
    sendMsg({
        channel: 'LCM',
        message: {
            channel: 'LCM',
            topic: 'WebService',
            subtopic: 'GetCallOutCome',
            source: 'SmartAgent',
            data: {
                callid: callId,
                AccountNumber: accountNumber
            }
        }
    });
};

export const getParentBusinessOutcomes = (callId: string, accountNumber: string) => {
    sendMsg({
        channel: 'LCM',
        message: {
            channel: 'LCM',
            topic: 'WebService',
            subtopic: 'GetBOGroupParent',
            source: 'SmartAgent',
            data: {
                callid: callId,
                AccountNumber: accountNumber
            }
        }
    });
};

export const getChildBusinessOutcomes = (
    callId: string,
    accountNumber: string,
    parentBusinessOutcomeId: string,
) => {
    sendMsg({
        channel: 'LCM',
        message: {
            channel: 'LCM',
            topic: 'WebService',
            subtopic: 'GetBusinessOutcomeParent',
            source: 'SmartAgent',
            data: {
                callid: callId,
                AccountNumber: accountNumber,
                ParentBusinessOutcomeId: parentBusinessOutcomeId
            }
        }
    });
};

export const getCampaignInfo = (callId: string, accountNumber: string) => {
    sendMsg({
        channel: 'LCM',
        message: {
            channel: 'LCM',
            topic: 'WebService',
            subtopic: 'GetModeInformation',
            source: 'SmartAgent',
            data: {
                callid: callId,
                AccountNumber: accountNumber
            }
        }
    });
};

export const sendBusinessOutcome = (outcome: BusinessOutcomeRequest) => {
    sendMsg({
        channel: 'LCM',
        message: {
            channel: 'LCM',
            topic: 'WebService',
            subtopic: 'SetBusinessOutcomeWithComments',
            source: 'SmartAgent',
            data: outcome
        }
    });
};

export const sendNotReadyState = (agentStatus: string) => {
    sendMsg({
        channel: 'Amazon',
        message: {
            channel: 'Amazon',
            topic: 'OutboundCall',
            subtopic: 'DropNailedCall',
            data: agentStatus,
            source: 'SmartAgent'
        }
    });
}