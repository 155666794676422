export interface CurrentCallInformation {
    agentUserId?: string;
    accountNumber?: string;
    callId?: string;
    contactNumber?: string;
    pacingMode?: 'PREVIEW' | 'PREDICTIVE' | 'PROGESSIVE';
    callStatus?: 'CustAnswered' | 'ACW' | 'dropped' | 'completed' | 'Preview' | 'initiate';
    businessFields?: CallBusinessFields;
    callOutcomes?: CallOutcome[];
    parentBusinessOutcomes?: CallOutcome[];
    childBusinessOutcomes?: CallOutcome[];
    campaignInfo?: CampaignData;
    callOutcome?: string;
    startTime?: number;
    endTime?: number;
    callType?: string;
    billAccount?: string;
    queueName?: string;
    acqueonInitialised?: boolean;
}

export interface CampaignData {
    [key: string]: string | number | boolean;
}

export interface CallBusinessFields {
    [key: string]: string;
}

export interface CallOutcome {
    id: string;
    label: string;
    leadScore?: string;
}

export enum ContactAttributes {
    CALL_TYPE = 'sa-tw-pci-call-type',
    LCM_KEY = 'sa-tw-pci-lcm-key',
    BILL_ACCOUNT = 'sa-tw-pci-bill-account'
}
