import { Button } from '@missionlabs/smartagent-app-components';
import classnames from 'classnames';
import { useEffect } from 'react';
import './view-switcher.scss';

interface Props {
    views: string[];
    selectedView: string;
    setSelectedView: (view: string) => void;
    callStatus?: string;
}

const ViewSwitcher = ({ views, selectedView, setSelectedView, callStatus }: Props) => {
    useEffect(() => {
        if (callStatus === 'ACW') {
            setSelectedView('Outcome');
        } else if (
            callStatus?.toLowerCase() === 'dropped' ||
            callStatus?.toLowerCase() === 'completed'
        ) {
            setSelectedView('Customer details');
        }
    }, [callStatus]);

    return (
        <div className="view-switcher">
            {views.map((view) => (
                <Button
                    onClick={() => setSelectedView(view)}
                    className={classnames({
                        'view-switcher-button': true,
                        selected: view === selectedView
                    })}
                    key={view}
                >
                    {view}
                </Button>
            ))}
        </div>
    );
};

export default ViewSwitcher;
