const isSandbox = process.env.REACT_APP_IS_SANDBOX === 'true';
const env = isSandbox ? 'sandbox' : process.env.REACT_APP_ENV || 'sandbox';

const urls: { [key: string]: string } = {
    sandbox: 'https://lhksmnfdq6.execute-api.eu-west-2.amazonaws.com/dev',
    dev: 'https://1fgjhkkc52.execute-api.eu-west-2.amazonaws.com/dev',
    uat: 'https://cwmnluhdzl.execute-api.eu-west-2.amazonaws.com/uat',
    prod: 'https://tx1l0m361d.execute-api.eu-west-2.amazonaws.com/prod'
};

export const BASE_API_URL = urls[env] || urls.dev;

const smartagentUrls: {[key: string]: string} = {
    sandbox: 'https://tw-sandbox.smartagent.app/',
    dev: 'https://dev-tw.smartagent.app/',
    uat: 'https://uat-tw.smartagent.app/',
    prod: 'https://tw.smartagent.app/'
}

export const SMARTAGENT_URL = smartagentUrls[env];
