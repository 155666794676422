import axios from 'axios';
import { BASE_API_URL } from './utils';

export enum LogLevel {
    ERROR = 'ERROR',
    DEBUG = 'DEBUG',
    INFO = 'INFO'
}

export interface Log {
    logLevel: LogLevel;
    message: string;
    username?: string;
    timestamp?: number;
}

const LOG_INTERVAL_MS = 10000;

export class LogHandler {
    static logs: Log[] = [];
    static saToken: string | undefined = undefined;
    static username: string | null = null;

    constructor() {
        setInterval(() => {
            this.sendLogs();
        }, LOG_INTERVAL_MS);
    }

    static setSaToken(token: string) {
        this.saToken = token;
    }

    static setUsername(username: string | null) {
        this.username = username;
    }

    static addLog(newLog: Log) {
        if (!this.username) return;
        this.logs.push({
            ...newLog,
            username: this.username,
            timestamp: Date.now()
        });
    }

    private sendLogs() {
        if (LogHandler.logs.length === 0) return;
        if (!LogHandler.saToken) return;
        axios
            .post(
                `${BASE_API_URL}/logs`,
                { logs: LogHandler.logs },
                {
                    headers: { 'X-Amz-Security-Token': LogHandler.saToken }
                }
            )
            .catch((err) => console.error('Failed to send logs ::', err));
        this.clearLogs();
    }

    private clearLogs() {
        LogHandler.logs = [];
    }
}
