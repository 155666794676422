import React from 'react';
import Main from './components/Main';
import { PluginHarness } from '@missionlabs/smartagent-app-components';

const App: React.FC = () => {
    const isEmbedded = window.parent !== window;

    return (
        <>
            {isEmbedded ? (
                <Main />
            ) : (
                <PluginHarness>
                    <Main />
                </PluginHarness>
            )}
        </>
    );
};

export default App;
