import { DataField } from '@missionlabs/smartagent-app-components';
import './campaign-info.scss';
import React from 'react';
import { CampaignData } from '../../types';

interface TableData {
    key: string;
    value: string;
}

interface Props {
    campaignInfo?: CampaignData;
    queueName?: string;
}
const CampaignInfo: React.FC<Props> = ({ campaignInfo, queueName }) => {
    const table: TableData[] = [];

    for (const key in campaignInfo) {
        table.push({ key: key, value: String(campaignInfo[key]) });
    }

    if (queueName) table.push({key: 'Queue Name', value: queueName});

    const arrayChunkSize = 4;

    const tableArr = table.reduce((resultArray: any[] = [], item, index) => {
        const chunkIndex = Math.floor(index / arrayChunkSize);

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
    }, []);


    return (
        <div className="container">
            {tableArr.map((row: [], idx) => (
                <div className="row" key={idx}>
                    {row.map((r: TableData, idx) => (
                        <div className="column" key={idx}>
                            <DataField basis={25} title={r.key}>
                                <div>{r.value ? r.value : '-'}</div>
                            </DataField>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default CampaignInfo;
