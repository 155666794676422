import React from 'react';
import { DataField } from '@missionlabs/smartagent-app-components';
import './customer-details.scss';
import { CallBusinessFields } from '../../types';

interface Props {
    contactData: CallBusinessFields;
}

interface TableData {
    name: string;
    value: string;
}

const CustomerDetails = ({ contactData }: Props) => {
    const arrayChunkSize = 4;

    const table: TableData[] = [];

    for (const key in contactData) {
        table.push({ name: key, value: String(contactData[key]) });
    }

    const tableArr = table.reduce((resultArray: any[] = [], item, index) => {
        const chunkIndex = Math.floor(index / arrayChunkSize);
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
    }, []);
    return (
        <div className="container">
            {tableArr.map((row: [], idx) => (
                <div className="row" key={idx}>
                    {row.map((r: CallBusinessFields, idx) => (
                        <div className="column" key={idx}>
                            <DataField basis={25} title={r.name}>
                                <div>{r.value ? r.value : '-'}</div>
                            </DataField>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default CustomerDetails;
